import CustomAxios from "../../utility/customAxios"
import {
    postNaturalPerson, postLegalEntity, getMeterSquares, getNegotiations, getLandSales, getLandSalesRowsCount
} from "../../resources/api-constants";
import { removeEmptyKeys } from "../../utility/commonFunctions";


export function postNaturalPersonForm(formValues:any) {
    return new Promise((resolve, reject) => {
        CustomAxios.post(postNaturalPerson(), 
            formValues,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => {
            console.log(response)
            resolve(response)
             //setPost(response.data);
        })
        .catch((error) => {
            console.log('errorApiTest', error)
            reject(error)
        });

    });
}

export function postLegalEntityForm(formValues:any) {
    return new Promise((resolve, reject) => {
        CustomAxios.post(postLegalEntity(), 
            formValues,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => {
            console.log(response)
            resolve(response)
             //setPost(response.data);
        })
        .catch((error) => {
            console.log('errorApiTest', error)
            reject(error)
        });

    });
}


// export function getTurnBusinessOptions() {
//     return new Promise((resolve, reject) => {
//         CustomAxios.get((getTurnBusiness())).then((response) => {
//             console.log(response)
//             resolve(response)
//              //setPost(response.data);
//         })
//         .catch((error) => {
//             console.log('errorApiTest', error)
//             reject(error)
//         });

//     });
// }


export function getMeterSquare() {
    return new Promise((resolve, reject) => {
        CustomAxios.get((getMeterSquares())).then((response) => {
            console.log(response)
            resolve(response)
             //setPost(response.data);
        })
        .catch((error) => {
            console.log('errorApiTest', error)
            reject(error)
        });

    });
}
export function getNegotiation() {
    return new Promise((resolve, reject) => {
        CustomAxios.get((getNegotiations())).then((response) => {
            console.log(response)
            resolve(response)
             //setPost(response.data);
        })
        .catch((error) => {
            console.log('errorApiTest', error)
            reject(error)
        });

    });
}


export interface LandSaleTableSearchParams {
    stateId?: string,
    transactionId?: string,
    sqmtrId?: string,
    
    
}
export function getLandSalesList(searchParams: LandSaleTableSearchParams) {
    console.log('searchParams', searchParams)

    

    let params:LandSaleTableSearchParams = removeEmptyKeys(searchParams)

        return new Promise((resolve, reject) => {
        CustomAxios.get(getLandSales(),  { params: params  }).then((response) => {
            console.log(response)
            resolve(response)
            //setPost(response.data);
        })
        .catch((error) => {
            console.log('errorApiTest', error)
            reject(error)
        });

    });
    
    
    // let data = JSON.stringify(searchParams);

    // let config = {
    //     method: 'get',
    //     maxBodyLength: Infinity,
    //     url: getLandSales(),
    //     headers: { 
    //       'Content-Type': 'application/json'
    //     },
    //     data : data
    // };


    // return new Promise((resolve, reject) => {
    //     CustomAxios.request(config).then((response) => {
    //         console.log(response)
    //         resolve(response)
    //          //setPost(response.data);
    //     })
    //     .catch((error) => {
    //         console.log('errorApiTest', error)
    //         reject(error)
    //     });

    // });

}
export function getLandSaleTableRowsCount() {
    return new Promise((resolve, reject) => {
        CustomAxios.get((getLandSalesRowsCount())).then((response) => {
            console.log(response)
            resolve(response)
             //setPost(response.data);
        })
        .catch((error) => {
            console.log('errorApiTest', error)
            reject(error)
        });

    });
}