import React, { useEffect, useState } from 'react'
import { MainTitle } from './Titles'
import Image from './Image'
import { getNoteList } from '../../services/adminContent/adminContentService'
import { formatDateHyphen } from '../../utility/commonFunctions'


interface Props {
    section :any
}
const Notes: React.FC<Props> = (props) => {

    const [notesList, setNotesList] = useState<any>([])
   
    const getNews = () => {
        let date = new Date().toDateString()
        let paramsTemp = {
            active: 1,
            status: 1,
            dateBegin: formatDateHyphen(date),
            dateEnd: formatDateHyphen(date),
            section: props.section,
            sectionId: props.section,
        }
       // dispatch(setLoader(true));

       getNoteList(paramsTemp).then((res: any) => {
            
            console.log('getLandSalesList', res.data);
            if (res.data){
                setNotesList(res.data)
                // dispatch(setLoader(false));
            }
            else{
                // dispatch(setLoader(false))
            }
        })
        .catch((error: any) => {
            //alert('Something went wrong')
            //setSelectStateOptions([])
            console.log('getStateList error', error)
            // dispatch(setLoader(false))
        })
    }
    
    useEffect(() => {
        getNews()
    }, [])


    
    const renderItem = (item:any) => {
        console.log(item)
        let url = item.url ? item.url : '#'
        return (
             <div className='news-item basicQuadImg '>
                {/* <Image name='home-new.jpg' className='news-img' /> */}
                <img src={item.imgUrl} className='news-img' />
                <div className="leyendaBasicImg caption caption-num4 Big_BG">
                    
                    <a
                        target="_blank"
                        className="btn_link"
                        href={url}
                    >
                        <Image name="hover_icon.png" />
                    </a>
                    
                    
                    <p className="textoProyecto">{item.noteDs}</p>
                    </div>
            </div>
    
            
    
            
        )
    }

    const renderNewsList = () => notesList.map((item:any) => {
        return renderItem(item)
    })
    return (
        <div className="news-container">
            <MainTitle text='Notas' />
            <div className='news-images'>
               {renderNewsList()}

            </div>

        </div>
    )
}

export default Notes