import React, { } from 'react'
import Image from '../components/common/Image'
import FolioTracking from '../components/common/FolioTracking'
import { MainTitle } from '../components/common/Titles'


export const Promotions: React.FC = () => {
    if(false){
    return (
        <div className='page-container page-suppliers'>
            <div className="banner-img">
                <Image name='promotions-banner1.png' className="supplier-banner-img" />
            </div>
            <FolioTracking />

            <div className='container main-container  mb-20'>
                <div className='page-content'>
                    <section>
                        <div className="title-wrapper  mb-20">
                            <MainTitle text='Promociones' />
                        </div>
                        <div className="row">
                            <div className="col offset-s3 s6 text-center">
                                <Image name='under-const-icon.png' className="icon-under-const" />
                                <MainTitle text='En Construccion' />
                            </div>
                            
                            
                            
                        </div>
                    </section>


                </div>
            </div>


        </div>
    )
    }else {
        return (  <div className='container main-container  mb-20'>
        <div className='page-content'>
            <section>
                <div className="title-wrapper  mb-20">
                    <MainTitle text='Promotions' />
                </div>
                <div className="row">
               Find out everything that happens in our stores and clubs     
                    
                    
                </div>
            </section>


        </div>
    </div>)
    }
}
