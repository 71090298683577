import React, {  } from 'react'
// import Image from '../common/Image'
import Row from './Row';

// import { Jsxlement } from 'typescript'



interface Props {
    
    columns : {label: string, key: string}[];
    data:any;
    callbackCheckbox?:any;
    callbackClickRow?:any;
    callbackEditRow?:any;
    callbackDeleteRow?:any;
    callbackImageCallback?:any;
    isEditable?:any;
    saveEditRow?:any
    handleSaveRowClick?:any
}

// const colTypes = {
//     image:'image',
//     text: 'text',
//     checkbox: 'checkbox',
//     cellRenderer: 'cellRenderer',
//     rowClick: 'rowClick',
//     rowEdit: 'rowEdit',
//     rowDelete: 'rowDelete',
//     imageCallback: 'imageCallback',
// }


const Rows:React.FC<Props> = (props) => {

    const rows = props.data;
    // const columns = props.columns;
    // const [editing]  = useState(false)   


//     const handleCheckBoxChange = (e:any, item:any) => {
//         console.log(e)
//         console.log(e.target.checked)
//         props.callbackCheckbox(item, e.target.checked)
//     }


//     const renderTextCol = (col:any, index:number, row:any)=> {
//         if(editing)
//         {
//             return (
//                 <td key={index} className={col.className}>
//                     <div className="tdParent">
//                         <div className="tdBefore">
//                             {col.label}
//                         </div>
//                         <div className="tdAfter"><input type="text" value={row[col.key]} /></div>
//                     </div>
//                 </td>
//             )
//         }
//         else{
//             return  (
//                 <td key={index} className={col.className}>
//                     <div className="tdParent">
//                         <div className="tdBefore">
//                             {col.label}
//                         </div>
//                         <div className="tdAfter">{row[col.key]}</div>
//                     </div>
//                 </td>
//             )
//         }
//     }

//     const handelRowClick = (row:any) => {
//         // if()
//         props.callbackClickRow(row)
//     }

//     const handelEditClick = (row:any) => {
//         // if(props.isEditable)
//         //     item
//         // else
//         props.callbackEditRow(row)

//         console.log(row)
        
//     }

//     const renderCols = (row:any) => {

//         let thList : any[] = []

//         //thList.push(<td></td>)
//         //console.log('row', row)
//         let tds = columns.map((col:any, index:number) => {
//             //console.log('col', col)
//             if(col.type == colTypes.text)
//             {
                
//                 return renderTextCol(col, index, row)
//             }
//             else if(col.type == colTypes.cellRenderer){
//                 return  (
//                     <td key={index}  className={col.className}>
//                         <div className="tdParent">
//                             <div className="tdBefore">
//                                 {col.label}
//                             </div>
//                             <div className="tdAfter"> { col.cellRenderer(row)} </div>
//                         </div>
                        
//                     </td>
//                 )
//             }
//             else if(col.type == colTypes.checkbox){
//                 return (
//                     <td key={index}  className={col.className}>
//                         <input type="checkbox" onChange={(e) =>handleCheckBoxChange(e, row)}></input>
//                     </td>
//                 )
//             }
//             else if(col.type == colTypes.rowClick){
//                 return (
//                     <td key={index}>
//                         <div className="tdParent">
//                             <div className="tdBefore">
//                                 {col.label}
//                             </div>
//                             <div className="tdAfter">
//                                 <span onClick={() => handelRowClick(row)}><Image name='dots.png'/></span>
//                             </div>
//                         </div>
//                     </td>

                   
//                 )
//             }
//             else if(col.type == colTypes.rowEdit){
//                 return (
//                     <td key={index}>
//                         <div className="tdParent">
//                             <div className="tdBefore">
//                                 {col.label}
//                             </div>
//                             <div className="tdAfter">
//                                 <span onClick={() => handelEditClick(row)}  className={col.className}><Image name='edit.png'/></span>
//                             </div>
//                         </div>
//                     </td>
//                 )
//             }
//             else if(col.type == colTypes.rowDelete){
//                 return (
//                     <td key={index}>
//                         <div className="tdParent">
//                             <div className="tdBefore">
//                                 {col.label}
//                             </div>
//                             <div className="tdAfter">
//                                 <span onClick={() => props.callbackDeleteRow(row)}  className={col.className}><Image name='trash.png'/></span>
//                             </div>
//                         </div>
//                     </td>
//                 )
               
//             }
//             else if(col.type == colTypes.imageCallback){
//                 return(
//                     <td key={index}  className={col.className}>
//                         <div className="tdParent">
//                             <div className="tdBefore">
//                                 {col.label}
//                             </div>
//                             <div className="tdAfter">
//                                 <span onClick={() => props.callbackImageCallback(row)}><Image name={col.image}/></span>
//                             </div>
//                         </div>
//                     </td>
//                 )
//             }
//             else if(col.type == colTypes.image){
//                 return(
//                     <td key={index}  className={col.className}>
//                         <div className="tdParent">
//                             <div className="tdBefore">
//                                 {col.label}
//                             </div>
//                             <div className="tdAfter">
//                                 <img src={row.imageUrl} alt=""  height="100" width="100" />
//                             </div>
//                         </div>
//                     </td>

                    
//                 )
//             }
           
            
//         })

//         thList.push(tds)
//         //thList.push(<td></td>) 

//         return thList
        
//    }


    const renderedRows = rows.map((item:any, index:number) => {
        if(item)
        {
            return (
               <Row 
                    columns={props.columns} 
                    callbackCheckbox={( e:any) =>props.callbackCheckbox(item, e)}
                    callbackClickRow={props.callbackClickRow}
                    callbackEditRow={props.callbackEditRow}
                    callbackDeleteRow={props.callbackDeleteRow}
                    callbackImageCallback={props.callbackImageCallback}
                    isEditable={props.isEditable}
                    row={item}
                    index={index}
                    saveEditRow={props.saveEditRow}
                    handleSaveRowClick={props.handleSaveRowClick}
               />
            )
        }
    })

    // const renderNewAddRow = () => {
    //     return (
    //         <tr key={'tr-999999'}>
    //             <td key={index}  className={col.className}>
                   
    //             </td>
    //         </tr>
    //     )
    // }
   
    return (
        <>
            {renderedRows}
            {/* {props.isEditable && renderNewAddRow()} */}
        </>
    )

}


export default Rows