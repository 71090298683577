import React, {  } from 'react'



export const Test: React.FC = () => {


  return (
    <div className='page-container page-suppliers'>
      
      
      <div className='container main-container'>
                <div className='page-content'>
                   Test f
                   sfdsfdsf
                   dsf
                   ds
                   f
                   
                </div>
            </div>
      

    </div>
  )
}
