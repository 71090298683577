import { USER_INTERNAL} from '../resources/project-constants'

export function removeEmptyKeys(params:any){
    let o = Object.keys(params)
    .filter((k) => params[k] != null && params[k] != '')
    .reduce((a, k) => ({ ...a, [k]: params[k] }), {});

    return o;
}



const validateEmail = (email:string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};


export function getDomainFromEmail  (email: string)  {

    if(!validateEmail(email))
        return false


    //var ind=email.indexOf("@");
    var domain=email.split('@').pop()
    if(domain)
    {
        var fullDomain= domain.split('.')
        domain =  fullDomain[0]

        console.log('fullDomain', fullDomain);
        console.log('domain', domain);

        return domain
    }
    
    

};



export function formatDate  (date: string)  {
    
    return date

}
export function formatDateHyphen  (dateStr: string)  {
    if(!dateStr)
        return ''
        
    var date = new Date(dateStr);

        let day = ("0" + date.getDate()).slice(-2)
        let month:any = ("0" + (date.getMonth() + 1)).slice(-2)
       // month =  month < 10 ? '0' + month : '' + month; // ('' + month) for string result
       

    let dateFinal = date.getFullYear() + '-' + month + '-' + day 
    
    return dateFinal

}

export function formatDateNotes  (dateStr: string)  {
        var date = new Date(dateStr);
        let datetemp:any = ("0" + (date.getDate())).slice(-2)
        let month:any = ("0" + (date.getMonth() + 1)).slice(-2)
        let dateFinal = date.getFullYear() + '-' + month + '-' + datetemp  + 'T00:00:00'
        //console.log('dateFinal', dateFinal)
        return dateFinal

       
        
    };

    export function formatDateNotesImages  (dateStr: string)  {
        var date = new Date(dateStr);
        let datetemp:any = ("0" + (date.getDate())).slice(-2)
        let month:any = ("0" + (date.getMonth() + 1)).slice(-2)
        let dateFinal = date.getFullYear() + '-' + month + '-' + datetemp  + ' ' + '00:00:00'
        //console.log('dateFinal', dateFinal)
        return dateFinal

       
        
    };

export function formatDateNews (dateStr: string)  {
        var date = new Date(dateStr);
        let dateFinal = date.getFullYear() + '/' + (date.getMonth()+1) + '/' +date.getDate() 
        console.log('dateFinal', dateFinal)
        return dateFinal

       
        
    };

    export function formatDateImages (dateStr: string)  {
//         const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
//   "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
// ];
     const day = (dt:any) => {
        if(dt.getDate() < 10){
            return ('0' + dt.getDate())
        }else{
            return dt.getDate() 
        } 
     } 
        var date = new Date(dateStr);
        let month:any = ("0" + (date.getMonth() + 1)).slice(-2)
        let dateFinal = day(date) + '/' + month  +'/' + date.getFullYear() 
        
        return dateFinal

       
        
    };


    export function formatPriceNumber  (str: string)  {
       return parseFloat(str.replace(/,/g, ''))

       
        
    };


    export function formatYesNo  (value: any)  {
        
        return((value ==  '1' || value ==  1)  ? 'Si' : 'NO')
    
    }



    export const canChangeUser = (loginUser:any) => {
        if (loginUser.boss) {
            return true
        }

        return false
    }
    export const canChangeStatus = (loginUser:any) => {
        if (loginUser.boss) {
            return false
        }

        return true
    }
    export const canChangeFolioActiveStatus = (loginType:any) => {
       // console.log('abc', USER_INTERNAL, loginType)
        if (loginType == USER_INTERNAL) {
            return true
        }

        return false
    }


    export const renderYesNo = (value:any) => {
        return value == 1 ? 'Si' : 'No';
    }

