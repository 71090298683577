import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';

export interface AuthState {
  value: number;
  status: 'idle' | 'loading' | 'failed';
  loginStatus: any;
  loginType: any;
  user: any;
  access: any;
  error: any;
  errorType: any;
}

const initialState: AuthState = {
    value: 0,
    status: 'idle',

    loginStatus: undefined,
    loginType: '', // db, external, internal
    user: '',
    access: '',
    error: false,
    errorType: '',
};



export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    setError: (state, action:PayloadAction<any>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.error = action.payload;
    },
    setErrorType: (state, action:PayloadAction<any>) => {
        
        state.errorType = action.payload;
    },
    setLoginType: (state, action:PayloadAction<any>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.loginType = action.payload;
    },
    setLoginStatus: (state, action:PayloadAction<any>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.loginStatus = action.payload;
    },
    setUser: (state, action:PayloadAction<any>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.user = action.payload;
    },
    setAccess: (state, action:PayloadAction<any>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.access = action.payload;
    },
    logOut: () => initialState,
    
    

    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase('fdsf', (state) => {
        state.status = 'loading';
      })
      
  },
});

export const { increment, decrement, incrementByAmount, setLoginType, setLoginStatus, setUser, setAccess, logOut, setError, setErrorType } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.auth.value;
export const getLoginStatus = (state: RootState) => state.auth.loginStatus;
export const getLoginType = (state: RootState) => state.auth.loginType;
export const getLoginUser = (state: RootState) => state.auth.user;
export const getLoginAccess = (state: RootState) => state.auth.access;
export const getError = (state: RootState) => state.auth.error;
export const getErrorType = (state: RootState) => state.auth.errorType;


export default authSlice.reducer;
